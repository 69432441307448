
import './Home.scss';
import { useHomeContext } from '../../state/Store'
import { Row, Col, Container } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel'
import { ICarouselData } from '../../state/IStore'
import { caraouselIO } from '../../io/CarouselIO'

function Home() {

    const [state, dispatch] = useHomeContext()!

    if (state.carousel?.state === 'UNINITIALIZED' || state.carousel?.state === undefined)
        caraouselIO(dispatch)

    return <Container className="carousel-container" >
        <Row className="align-items-center" >
            <Col lg={{ span: 8 }} className="mx-auto">
                <CarouselRender items={state.carousel?.data ?? []} />
            </Col>
        </Row >
    </Container>



}


const CarouselRender: React.FC<{ items: ICarouselData[] }> = (props) => {


    return <Carousel fade variant="dark">
        {getCarouselItems(props.items)}
    </Carousel>

}

const getCarouselItems = (items: ICarouselData[]) => {

    return items.map(value => {

        return (<Carousel.Item>
            <img
                className='carousel-img'
                src={value.image}
                alt={value.alt}
            />
            <Carousel.Caption>
                <h5>{value.heading}</h5>
                <p>{value.description}</p>
            </Carousel.Caption>
        </Carousel.Item>)
    })
}

export default Home