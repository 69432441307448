
import './ContactUs.scss'
import { useContactUsContext } from '../../state/Store'
import { Row, Col, Container } from 'react-bootstrap'
import { contactUsIO } from '../../io/ContactUsIO'
import { IContactData } from '../../state/IStore'

function ContactUs() {

    const [state, dispatch] = useContactUsContext()!
    if (state.contact?.state === 'UNINITIALIZED' || state.contact?.state === undefined) {
        contactUsIO(dispatch)
    }

    return (

        <div className="contactus" >

            <Col className="mx-auto">
                <Container className="heading-container">

                    <Row>
                        <Col><h4>For any queries, reach out to us via following mediums:</h4></Col>
                    </Row>
                </Container>
                <hr />
                <Container className="data-container">
                    <CommunicationMediums items={state.contact?.data ?? []} />
                </Container>
            </Col>

        </div>

    )
}

const CommunicationMediums: React.FC<{ items: IContactData[] }> = (props) => {

    return <>{props.items

        .map((value, index) => {

            if (value.type === 'Email')
                return <Row key={index}>
                    <Col><strong><text>{value.label}: </text></strong><a href={`mailto: ${value.value}`} >{value.value}</a></Col>
                </Row>
            if (value.type === 'Link')
                return <Row key={index}>
                    <Col><strong><text>{value.label}: </text></strong><a href={`//${value.value}`} target='_blank'>{value.value}</a></Col>
                </Row>
            if (value.type === 'Text')
                return <Row key={index}>
                    <Col><strong><text>{value.label}: </text></strong>{value.value}</Col>
                </Row>

            return <></>
        })}
    </>

}

export default ContactUs