
import './App.scss';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Image from 'react-bootstrap/Image'
import homeLogo from './assets/images/logo.png'
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom"
import Home from './pages/home/Home';
import { ContactUsContextProvider, HomeContextProvider } from './state/Store';
import ContactUs from './pages/contactUs/ContactUs';
//import Col from 'react-bootstrap/Col'

function App() {

  return (

    <Router>
      <div>
        <div className="App">
          <AIANavbar />
          <Routes>
            <Route path="/about">

            </Route>
            <Route path="/users">

            </Route>
            <Route path="/contactus" element={<ContactUsContextProvider><ContactUs /></ContactUsContextProvider>}>

            </Route>
            <Route path="/" element={<HomeContextProvider><Home /></HomeContextProvider>}>
            </Route>
          </Routes>
        </div>

      </div>
    </Router >
  )
}

const AIANavbar: React.FC<{}> = () => {

  return (<Navbar bg="transparent" expand="lg">
    <Container>
      <Navbar.Brand href="/">

        <Image src={homeLogo} alt="home logo" className="Logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav>
          <Nav.Link href="/">Home</Nav.Link>
          <NavDropdown title="Products" id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="#link">About Us</Nav.Link>
          <Nav.Link href="/contactus">Contact Us</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>)

}

export default App;
