export const data = [

    {
        "image": require("../assets/images/carousel/image-1.jpg").default,
        "heading": "A Grade quality essential oils",
        "description": "Our essential oils are manufatured using approved menthods and from most pristine sources available."
    },
    {
        "image": require("../assets/images/carousel/image-2.jpg").default,
        "heading": "Wide range products",
        "description": "We have a wide range of collection of high quality essential oils available."
    }
]