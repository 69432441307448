import { data } from '../mocks/ContactUsData'
import env from '../config/env.json'
import urls from '../config/urls.json'
import { fetchContactUsData, fetchContactUsDataInProgress } from '../state/Actions';

const axios = require('axios').default;

const contactUsIO = async (dispatch: React.Dispatch<any>) => {

    dispatch(fetchContactUsDataInProgress())

    if (!env.isProduction) {

        dispatch(fetchContactUsData(data))
        return
    }

    try {
        const response = await axios.get(urls.urls.contactUsData)
        response.status === 200 && dispatch(fetchContactUsData(response.data))

    } catch (error) {
        console.error(error);
        dispatch(fetchContactUsData([]))
    }

}

export { contactUsIO }