import { FETCH_CAROUSEL_DATA, FETCH_CAROUSEL_DATA_INPROGRESS, FETCH_CONTACTUS_DATA, FETCH_CONTACTUS_DATA_INPROGRESS } from './Actions'
import { ICarouselData, IValue, IContactUsData, IHomeData, IContactData } from './IStore'


export function homeReducer(state: IHomeData, action: { type: any, data: Array<ICarouselData> }): IHomeData {

    switch (action.type) {

        case FETCH_CAROUSEL_DATA_INPROGRESS:
            return { ...state, carousel: { data: [], state: 'INPROGRESS' } }
        case FETCH_CAROUSEL_DATA:
            return { ...state, carousel: { data: [...action.data], state: 'INITIALIZED' } }
        default:
            return state;
    }
}

export function contactUsReducer(state: IContactUsData, action: { type: any, data: Array<IContactData> }): IContactUsData {

    switch (action.type) {

        case FETCH_CONTACTUS_DATA_INPROGRESS:
            return { ...state, contact: { data: [], state: 'INPROGRESS' } }
        case FETCH_CONTACTUS_DATA:
            return { ...state, contact: { data: [...action.data], state: 'INITIALIZED' } }
        default:
            return state;
    }
}


