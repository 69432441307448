import React, { createContext, useReducer, useContext } from "react"
import { ICarouselData, IContactData, IHomeData, IContactUsData } from './IStore'
import { homeReducer, contactUsReducer } from "./Reducers"



const HomeContext: React.Context<[IHomeData, React.Dispatch<{
    type: any
    data: ICarouselData[]
}>] | undefined> = React.createContext<[IHomeData, React.Dispatch<{
    type: any
    data: ICarouselData[]
}>] | undefined>(undefined)

const ContactUsContext: React.Context<[IContactUsData, React.Dispatch<{
    type: any
    data: IContactData[]
}>] | undefined> = React.createContext<[IContactUsData, React.Dispatch<{
    type: any
    data: IContactData[]
}>] | undefined>(undefined)

const HomeContextProvider: React.FC<{}> = (props) => {

    const dataAndDispatch = useReducer(homeReducer, {})
    return <HomeContext.Provider value={dataAndDispatch} {...props} />

}

const ContactUsContextProvider: React.FC<{}> = (props) => {

    const dataAndDispatch = useReducer(contactUsReducer, {})
    return <ContactUsContext.Provider value={dataAndDispatch} {...props} />

}

function useHomeContext(): [IHomeData, React.Dispatch<{
    type: any
    data: ICarouselData[]
}>] | undefined {

    return useContext(HomeContext)
}

function useContactUsContext(): [IContactUsData, React.Dispatch<{
    type: any
    data: IContactData[]
}>] | undefined {

    return useContext(ContactUsContext)
}

export { HomeContextProvider, ContactUsContextProvider, useHomeContext, useContactUsContext }


