import { ICarouselData, IContactData } from "./IStore";

export const FETCH_CAROUSEL_DATA_INPROGRESS = "FETCH_CARAOUSEL_DATA_INPROGRESS";
export const FETCH_CAROUSEL_DATA = "FETCH_CARAOUSEL_DATA";
export const FETCH_CONTACTUS_DATA_INPROGRESS = "FETCH_CONTACTUS_DATA_INPROGRESS";
export const FETCH_CONTACTUS_DATA = "FETCH_CONTACTUS_DATA";
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_TEAM = "FETCH_TEAM";

export function fetchCaraouselDataInProgress() {
    return { type: FETCH_CAROUSEL_DATA_INPROGRESS, data: [] }
}

export function fetchCaraouselData(data: Array<ICarouselData>) {
    return { type: FETCH_CAROUSEL_DATA, data }
}

export function fetchContactUsDataInProgress() {
    return { type: FETCH_CONTACTUS_DATA_INPROGRESS, data: [] }
}

export function fetchContactUsData(data: Array<IContactData>) {
    return { type: FETCH_CONTACTUS_DATA, data }
}

export function fetchProducts() {
    return { type: FETCH_PRODUCTS }
}

export function fetchTeam() {
    return { type: FETCH_TEAM }
}