export const data = [

    {
        "type": "Email",
        "label": "Email",
        "value": "contactus@ancientindianaromatics.com"
    },
    {
        "type": "Link",
        "label": "Facebook",
        "value": "facebook.com/aia"
    },
    {
        "type": "Text",
        "label": "Phone",
        "value": "+91-000000000"
    }
]   