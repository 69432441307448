import { data } from '../mocks/CarouselData'
import env from '../config/env.json'
import urls from '../config/urls.json'
import { fetchCaraouselData, fetchCaraouselDataInProgress } from '../state/Actions';

const axios = require('axios').default;

const caraouselIO = async (dispatch: React.Dispatch<any>) => {

    dispatch(fetchCaraouselDataInProgress())

    if (!env.isProduction) {

        dispatch(fetchCaraouselData(data))
        return
    }

    try {

        const response = await axios.get(urls.urls.carouselData)
        response.status === 200 && dispatch(fetchCaraouselData(response.data))

    } catch (error) {
        console.error(error);
        dispatch(fetchCaraouselData([]))
    }

}

export { caraouselIO }